'use strict';

module.exports = function () {
    var elements = $('.hk-external-entry-point,[href="#/hk-entry-widget-open"]');
    elements.css('pointer-events', 'none');

    function attachHkEntryPointEvents() {
        console.log($('#hk-entry-widget').length);
        if ($('#hk-entry-widget').length) {
            /* On each element attach a click handler that dispatches a new Event of type "hk-entry-widget-open" to the DOM Element with the id "hk-entry-widget" */
            elements.each(function(index, element) {
                $(element).on('click', function() {
                    var customEvent = new CustomEvent('hk-entry-widget-open', { detail: element });
                    $('#hk-entry-widget')[0].dispatchEvent(customEvent);
                });
                $(element).css('pointer-events', 'auto');
            });
        } else {
            setTimeout(attachHkEntryPointEvents, 1000);
        }
    }
    attachHkEntryPointEvents();
};
